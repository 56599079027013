import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import SoftButton from 'components/SoftButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import SoftBox from '../../../../components/SoftBox';
import SoftTypography from '../../../../components/SoftTypography';
import colors from '../../../../assets/theme/base/colors';
import typography from '../../../../assets/theme/base/typography';
import BlockRenderer from 'components/StrapiBlockRenderer';

const WebinarCard = ({ webinar }) => {
  const { t } = useTranslation();
  const { size, fontWeightBold } = typography;

  return (
    <Card sx={{ mb: '1.5rem' }}>
      <CardContent
        sx={{
          px: '4rem',
          py: '1.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SoftBox>
          <SoftTypography
            variant="h5"
            sx={{
              color: colors.text.headingGrey,
              fontWeight: fontWeightBold,
              fontSize: size.lg,
            }}
          >
            {webinar.attributes.name}
          </SoftTypography>
          <SoftTypography
            variant="body2"
            mt={1}
            sx={{ color: colors.black.main }}
          >
            <BlockRenderer content={webinar.attributes.descriptionRichText} />
          </SoftTypography>
        </SoftBox>
        <SoftButton
          variant="outlined"
          color="secondary"
          sx={{
            width: '12.5rem',
            height: '2rem',
            '&:hover': { borderColor: '#0a76ec' },
          }}
          href={webinar.attributes.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SoftTypography variant="button">
            {t('WEBINARS.viewWebinarButton')}
          </SoftTypography>
          <ArrowForwardIosIcon sx={{ ml: '1rem' }} />
        </SoftButton>
      </CardContent>
    </Card>
  );
};

WebinarCard.propTypes = {
  webinar: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      descriptionRichText: PropTypes.array,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WebinarCard;
