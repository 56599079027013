import React, { useState } from 'react';
import styles from './pending-quotes.module.scss';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import SoftTypography from '../../../components/SoftTypography';
import { useTranslation } from 'react-i18next';
import PendingQuotesTable from './pending-quotes-table/pending-quotes-table';
import useFetchPendingQuotes from './useFetchPendingQuotes';
import { emit$ } from 'shared/services/app-events';
import { AppEvents } from 'shared/services/app-events';
import SoftBox from '../../../components/SoftBox';
import { useGenerateUuid } from 'shared/utils/useGenerateUuid';
import SearchField from 'shared/components/SearchField/SearchField';

const PendingQuotes = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const { data, fetchData } = useFetchPendingQuotes(searchTerm);
  const tableUuid = useGenerateUuid();

  const handleSearchButtonClick = () => {
    emit$(AppEvents.RESET_TABLE_COUNTER, tableUuid);
    fetchData(searchTerm.trim());
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.PendingQuotes} data-testid="PendingQuotes">
        <SoftBox sx={{ marginTop: '6rem' }}>
          <SoftTypography
            variant="h2"
            fontWeight="bold"
            sx={{ marginBottom: '2rem' }}
          >
            {t('HOME.PENDING_QUOTES.table.label')}
          </SoftTypography>
        </SoftBox>
        <SearchField
          searchFunc={handleSearchButtonClick}
          searchCriteria={searchTerm}
          onSearchCriteriaChange={(event) =>
            setSearchTerm(event.target.value.slice(0, 50))
          }
          inputLabel={t('HOME.PENDING_QUOTES.search')}
          inputPlaceholder={t('HOME.PENDING_QUOTES.search')}
          buttonText={t('HOME.PENDING_QUOTES.searchButton')}
          containerStyles={styles.searchContainer}
          inputStyles={styles.searchInput}
        />
      </div>
      <PendingQuotesTable tableUuid={tableUuid} data={data} />
    </DashboardLayout>
  );
};

PendingQuotes.propTypes = {};

PendingQuotes.defaultProps = {};

export default PendingQuotes;
