import { useContext } from 'react';

// react-router-dom components
import { useLocation, useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';

// Authentication layout components
import authFctgBackground from 'assets/images/background_login_fctg.jpg';
import AuthLayout from '../components/AuthLayout';
import { AuthContext } from '../../../AuthContext';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';

import logo from '../../../assets/images/logo2.svg';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import colors from 'assets/theme/base/colors';
import { usePortalApi } from '../../../shared/const/api-backend';
import { useForm } from 'react-hook-form';

import './login-fctg.scss';
import { UserDataContext } from '../../../shared/context/userDataContext';
import { useTranslation } from 'react-i18next';
import typography from '../../../assets/theme/base/typography';
import { emailValidation } from '../../../shared/utils/formValidations';

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

function FctgLogin() {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectToAfterLogin = decodeURIComponent(urlParams.get('redirectTo'));
  const API = usePortalApi();
  const navigate = useNavigate();
  const { saveToken, role } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const { fetchUserDataFromApi } = useContext(UserDataContext);
  const { black, white, buttons } = colors;
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const { size, lineHeight, fontWeightBold, fontWeightRegular } = typography;

  const onSubmit = async () => {
    const { email, password } = getValues();
    if (!email || !password) {
      showNotification('COMMON.error.fillAllFields', 'error');
      return;
    }
    setLoading(true);
    try {
      const url = `${apiStrapiUrl}auth/local`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identifier: email,
          password: password,
        }),
      });
      if (!response.ok) {
        throw new Error('requestFailed');
      }
      const jsonResponse = await response.json();
      saveToken(jsonResponse.jwt, 'fctg');
      fetchUserDataFromApi();
      const meResponse = await API.get(`${apiStrapiUrl}users/me?populate=role`);

      if (meResponse.status !== 200) {
        throw new Error('requestFailed');
      }

      showNotification('NOTIFICATION.success.login', 'success');
      // @TODO temporary redirect to dashboard, should be welcome page - ESHOP-173
      navigate(redirectToAfterLogin ? redirectToAfterLogin : 'dashboard');

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.message === 'requestFailed') {
        showNotification('COMMON.error.login', 'error');
      } else if (error.message === 'noAssignedBrands') {
        showNotification('COMMON.error.noAssignedBrands', 'error');
      } else {
        showNotification('COMMON.error.fetchingData', 'error');
      }
    }
  };

  const handleProviderLogin = (provider) => {
    window.location.href = `${apiStrapiUrl}connect/${provider}`;
    const redirectTo = urlParams.get('redirectTo');
    let backlinkUrl = `${apiStrapiUrl}connect/${provider}`;
    if (redirectTo) {
      backlinkUrl += `?backlink=${encodeURIComponent(redirectTo)}`;
    }
    window.location.href = backlinkUrl;
  };

  return (
    <AuthLayout image={authFctgBackground}>
      <Card sx={{ borderRadius: '10px' }}>
        <SoftBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: white.main,
            padding: '24px',
            width: '100%',
          }}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              minWidth: '35%',
              mt: '1rem',
            }}
          />
          <SoftBox
            component="div"
            sx={{
              mt: '1.75rem',
              width: '100%',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mb: 2 }}>
                <SoftBox sx={{ border: 1, borderRadius: 2 }}>
                  <SoftInput
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    size="small"
                    error={errors.email}
                    inputProps={{
                      'data-cy': 'agentP_signIn_email_input',
                    }}
                    {...register('email', emailValidation)}
                  />
                </SoftBox>
                {errors.email && (
                  <span className="error-message">{errors.email.message}</span>
                )}
              </Box>

              <Box sx={{ mb: 2 }}>
                <SoftBox sx={{ border: 1, borderRadius: 2 }}>
                  <SoftInput
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    size="small"
                    error={errors.password}
                    inputProps={{
                      'data-cy': 'agentP_signIn_password_input',
                    }}
                    {...register('password', {
                      required: 'Password is required',
                      email: true,
                    })}
                  />
                </SoftBox>
                {errors.password && (
                  <span className="error-message">
                    {errors.password.message}
                  </span>
                )}
              </Box>

              <SoftBox mt={2} mb={1}>
                <SoftButton
                  onClick={handleSubmit}
                  variant="gradient"
                  type="submit"
                  size="small"
                  sx={{
                    mb: 1,
                    textTransform: 'none',
                    backgroundColor: buttons.primary.background,
                    '&:hover': {
                      backgroundColor: buttons.primary.background,
                    },
                    color: buttons.primary.text,
                    borderRadius: '4px',
                  }}
                  fullWidth
                  data-cy="agentP_signIn_logIn_btn"
                >
                  {t('AUTH.LOGIN.login')}
                </SoftButton>
              </SoftBox>
            </form>
            <SoftBox className="divider">
              <SoftBox className="divider__line"></SoftBox>
              <SoftBox className="divider__text">{t('AUTH.LOGIN.or')}</SoftBox>
              <SoftBox className="divider__line"></SoftBox>
            </SoftBox>
            <SoftBox
              component="div"
              sx={{
                width: '100%',
              }}
            >
              <SoftButton
                onClick={() => handleProviderLogin('microsoft')}
                variant="gradient"
                type="submit"
                size="small"
                sx={{
                  textTransform: 'none',
                  backgroundColor: buttons.secondary.background,
                  '&:hover': {
                    backgroundColor: buttons.secondary.background,
                  },
                  color: buttons.secondary.text,
                  borderRadius: '4px',
                }}
                fullWidth
                data-cy="agentP_signIn_azure_btn"
              >
                {t('AUTH.LOGIN.azureLogin')}
              </SoftButton>
              <SoftButton
                onClick={() => handleProviderLogin('okta')}
                variant="gradient"
                type="submit"
                size="small"
                sx={{
                  textTransform: 'none',
                  backgroundColor: buttons.green.background,
                  '&:hover': {
                    backgroundColor: buttons.green.background,
                  },
                  color: buttons.secondary.text,
                  borderRadius: '4px',
                }}
                fullWidth
                data-cy="agentP_signIn_okta_btn"
              >
                {t('AUTH.LOGIN.oktaGlobalLogin')}
              </SoftButton>
              <SoftButton
                onClick={() => handleProviderLogin('okta_external')}
                variant="gradient"
                type="submit"
                size="small"
                sx={{
                  textTransform: 'none',
                  backgroundColor: buttons.green.background,
                  '&:hover': {
                    backgroundColor: buttons.green.background,
                  },
                  color: buttons.secondary.text,
                  borderRadius: '4px',
                }}
                fullWidth
                data-cy="agentP_signIn_okta_btn"
              >
                {t('AUTH.LOGIN.oktaExternalLogin')}
              </SoftButton>
            </SoftBox>
          </SoftBox>
          <SoftBox
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '2.5rem',
            }}
          >
            <SoftBox>
              <SoftTypography
                sx={{
                  fontSize: size.xxs,
                  fontWeight: fontWeightRegular,
                  color: black.main,
                  textAlign: 'left',
                }}
              >
                {t('AUTH.LOGIN.helpLogin')}
              </SoftTypography>
              <SoftTypography
                sx={{
                  fontSize: size.xxs,
                  fontWeight: fontWeightRegular,
                  textDecoration: 'underline',
                  '&:hover': {
                    color: buttons.secondary.background,
                  },
                }}
              >
                <Link href="mailto:email@partner-email.com.au">
                  email@partner-email.com.au
                </Link>
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              <SoftTypography
                sx={{
                  fontSize: size.xxs,
                  fontWeight: fontWeightRegular,
                  color: black.main,
                  textAlign: 'right',
                }}
              >
                {t('AUTH.LOGIN.contactSales')}
              </SoftTypography>
              <SoftTypography
                sx={{
                  fontSize: size.xxs,
                  fontWeight: fontWeightRegular,
                  textDecoration: 'underline',
                  '&:hover': {
                    color: buttons.secondary.background,
                  },
                }}
              >
                <Link href="mailto:sales@partner-email.com.au">
                  sales@partner-email.com.au
                </Link>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </AuthLayout>
  );
}

export default FctgLogin;
