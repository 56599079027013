import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './paymentTab.scss';
import { Grid } from '@mui/material';
import CurrencyComponent from '../../../../shared/components/Currency/Currency';

const PaymentTab = ({ policyDetails }) => {
  const { t } = useTranslation();

  const getInitialValues = () => {
    const isValidPolicyValue6 =
      policyDetails?.policy.value6 !== undefined &&
      policyDetails?.policy.value6 !== '' &&
      !policyDetails?.policy.value6.includes('/') &&
      parseFloat(policyDetails.policy.value6);

    return {
      grossPremium: (
        <CurrencyComponent
          value={policyDetails.policy.price.grossPremium}
          countryCode={policyDetails.policy.price.currency}
        />
      ),

      discountAmount: policyDetails ? (
        <CurrencyComponent
          value={
            policyDetails.policy.price.grossPremiumBeforePromotion -
            policyDetails.policy.price.grossPremium
          }
          countryCode={policyDetails.policy.price.currency}
        />
      ) : undefined,

      tax: (
        <CurrencyComponent
          value={policyDetails.policy.price.tax}
          countryCode={policyDetails.policy.price.currency}
        />
      ),

      netPrice: (
        <CurrencyComponent
          value={policyDetails.policy.price.netPrice}
          countryCode={policyDetails.policy.price.currency}
        />
      ),

      promotion: policyDetails?.policy.price.promotion
        ? t('COMMON.yes')
        : t('COMMON.no'),

      grossPremiumBeforePromotion: (
        <CurrencyComponent
          value={policyDetails.policy.price.grossPremiumBeforePromotion}
          countryCode={policyDetails.policy.price.currency}
        />
      ),

      promotionCode:
        policyDetails?.policy.price.promotionCode || t('COMMON.notAvailable'),

      refundAmount:
        isValidPolicyValue6 > 0 ? (
          <CurrencyComponent
            value={policyDetails.policy.value6}
            countryCode={policyDetails.policy.price.currency}
          />
        ) : undefined,

      additionalCost:
        isValidPolicyValue6 < 0 ? (
          <CurrencyComponent
            value={Math.abs(parseFloat(policyDetails.policy.value6))}
            countryCode={policyDetails.policy.price.currency}
          />
        ) : undefined,

      retailNet: policyDetails?.policy.additionalValues[0]?.data.retailNet ? (
        <CurrencyComponent
          value={policyDetails.policy.additionalValues[0].data.retailNet}
          countryCode={policyDetails.policy.price.currency}
        />
      ) : undefined,
    };
  };

  const paymentType =
    policyDetails?.payment.provider || t('POLICY_DETAILS.PAYMENT.onAccount');

  const initialValues = getInitialValues();
  const nonEmptyInitialValues = Object.fromEntries(
    Object.entries(initialValues).filter(([_, value]) => value !== undefined),
  );

  return (
    <div className="paymentTab">
      <div className="header">
        <h3>{t('POLICY_DETAILS.tabPayment')}</h3>
      </div>
      <Grid container spacing={2}>
        {Object.entries(nonEmptyInitialValues).map(([name, value], index) => (
          <Grid item xs={12} md={6} key={index} className="field-container">
            <div className="label-text">
              {t(`POLICY_DETAILS.PAYMENT.${name}`)}
            </div>
            <div className="text-value">{value}</div>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12} md={6} className="field-container">
        <div className="label-text">
          {t(`POLICY_DETAILS.PAYMENT.paymentType`)}
        </div>
        <div className="text-value">{paymentType}</div>
      </Grid>
    </div>
  );
};

PaymentTab.propTypes = {
  policyDetails: PropTypes.object,
};

export default PaymentTab;
