import PropTypes from 'prop-types';
import styles from './searchField.module.scss';
import SuiInput from '../../../components/SoftInput';
import SoftButton from '../../../components/SoftButton';
import SoftBox from '../../../components/SoftBox';

const SearchField = ({
  searchFunc,
  searchCriteria,
  onSearchCriteriaChange,
  inputLabel,
  inputPlaceholder,
  buttonText,
  containerStyles = '',
  inputStyles = '',
}) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchFunc(searchCriteria);
    }
  };

  return (
    <div className={`${styles['search-field']}`}>
      <div className={`${styles['search-container']} ${containerStyles}`}>
        <SoftBox className={`${styles['search-input']} ${inputStyles}`}>
          <SuiInput
            type="search"
            placeholder={inputPlaceholder}
            label={inputLabel}
            value={searchCriteria}
            onChange={onSearchCriteriaChange}
            variant="outlined"
            onKeyPress={handleKeyPress}
          />
        </SoftBox>
        <SoftButton
          variant="outlined"
          color="info"
          onClick={searchFunc}
          style={{ color: '#0a76ec', borderColor: '#0a76ec' }}
        >
          {buttonText}
        </SoftButton>
      </div>
    </div>
  );
};

SearchField.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  searchCriteria: PropTypes.string.isRequired,
  onSearchCriteriaChange: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  containerStyles: PropTypes.string,
  inputStyles: PropTypes.string,
};

export default SearchField;
