/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { useCallback } from 'react';

function Breadcrumbs({ title, route, light }) {
  const routes = route.slice(0, -1);
  const getValidLink = useCallback((link) => {
    switch (typeof link) {
      case 'string':
        return String(link);
      case 'object':
        return String(link.link);
      default:
        throw new Error(`${typeof link} not supported`);
    }
  }, []);
  const getValidRouteName = useCallback((link) => {
    switch (typeof link) {
      case 'string':
        return String(link);
      case 'object':
        return String(link.title);
      default:
        throw new Error(`${typeof link} not supported`);
    }
  }, []);

  return (
    <SoftBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({ palette: { white, grey } }) =>
              light ? white.main : grey[600],
          },
        }}
      >
        {routes.map((el) => (
          <Link to={getValidLink(el)} key={el}>
            <SoftTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? 'white' : 'dark'}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {getValidRouteName(el)}
            </SoftTypography>
          </Link>
        ))}
        <SoftTypography
          variant="button"
          fontWeight="bold"
          fontSize="1.5rem"
          textTransform="capitalize"
          color={light ? 'white' : 'dark'}
          sx={{ lineHeight: 0 }}
        >
          {title.replace('-', ' ')}
        </SoftTypography>
      </MuiBreadcrumbs>
      {/*<SoftTypography*/}
      {/*  fontWeight="bold"*/}
      {/*  textTransform="capitalize"*/}
      {/*  variant="h6"*/}
      {/*  color={light ? "white" : "dark"}*/}
      {/*  noWrap*/}
      {/*>*/}
      {/*  {title.replace("-", " ")}*/}
      {/*</SoftTypography>*/}
    </SoftBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
