import React, { useState } from 'react';
import styles from './policies.module.scss';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import { useTranslation } from 'react-i18next';
import useFetchPoliciesData from './useFetchPoliciesData';
import PoliciesTable from './policies-table.js/policies-table';
import SoftTypography from 'components/SoftTypography';
import SoftBox from '../../../components/SoftBox';
import { useGenerateUuid } from 'shared/utils/useGenerateUuid';
import { emit$, AppEvents } from 'shared/services/app-events';
import SearchField from 'shared/components/SearchField/SearchField';

const Policies = () => {
  const { t } = useTranslation();
  const [searchPolicyId, setSearchPolicyId] = useState('');
  const { data, fetchData } = useFetchPoliciesData();

  const tableUuid = useGenerateUuid();

  const handleSearchButtonClick = () => {
    emit$(AppEvents.RESET_TABLE_COUNTER, tableUuid);
    fetchData(searchPolicyId.trim());
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.Policies} data-testid="Policies">
        <SoftBox
          sx={{
            marginTop: '6rem',
          }}
        >
          <SoftTypography
            variant="h2"
            fontWeight="bold"
            sx={{ marginBottom: '2rem' }}
          >
            {t('POLICIES.header')}
          </SoftTypography>
        </SoftBox>
        <SearchField
          searchFunc={handleSearchButtonClick}
          searchCriteria={searchPolicyId}
          onSearchCriteriaChange={(event) =>
            setSearchPolicyId(event.target.value)
          }
          inputLabel={t('POLICIES.search')}
          inputPlaceholder={t('POLICIES.search')}
          buttonText={t('POLICIES.searchButton')}
        />
        <PoliciesTable tableUuid={tableUuid} data={data}></PoliciesTable>
      </div>
    </DashboardLayout>
  );
};

Policies.propTypes = {};

Policies.defaultProps = {};

export default Policies;
