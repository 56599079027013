import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import BlockRenderer from 'components/StrapiBlockRenderer';

const FAQItem = ({ faq }) => {
  return (
    <Accordion sx={{ mb: '1.5rem', px: '4rem', py: '1.5rem' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {faq.attributes.question}
      </AccordionSummary>
      <AccordionDetails>
        <BlockRenderer content={faq.attributes.answerRichText} />
      </AccordionDetails>
    </Accordion>
  );
};

FAQItem.propTypes = {
  faq: PropTypes.shape({
    attributes: PropTypes.shape({
      question: PropTypes.string.isRequired,
      answerRichText: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export default FAQItem;
