import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Stack } from '@mui/material';
import SoftButton from 'components/SoftButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import colors from '../../../../assets/theme/base/colors';
import typography from '../../../../assets/theme/base/typography';
import SoftTypography from '../../../../components/SoftTypography';
import BlockRenderer from 'components/StrapiBlockRenderer';

const apiStrapiMediaUrl = process.env.REACT_APP_STRAPI_API.replace(
  /\/api\/?$/,
  '',
);

const ResourceCard = ({ resource }) => {
  const { t } = useTranslation();
  const { size, fontWeightBold } = typography;

  return (
    <Card sx={{ mb: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        m={4}
        alignItems="center"
      >
        {resource?.attributes?.image?.data && (
          <Box
            component="img"
            src={`${apiStrapiMediaUrl}${resource?.attributes?.image?.data?.attributes?.formats?.thumbnail?.url}`}
            sx={{ maxHeight: 100, maxWidth: 200, borderRadius: '1rem' }}
          />
        )}
        <CardContent sx={{ width: 1500 }}>
          <SoftTypography
            variant="h5"
            sx={{
              color: colors.text.headingGrey,
              fontWeight: fontWeightBold,
              fontSize: size.lg,
            }}
          >
            {resource.attributes.name}
          </SoftTypography>
          <SoftTypography
            variant="body2"
            mt={1}
            sx={{ color: colors.black.main }}
          >
            <BlockRenderer content={resource.attributes.descriptionRichText} />
          </SoftTypography>
        </CardContent>
        <SoftButton
          variant="outlined"
          color="secondary"
          sx={{
            width: 300,
            height: '50%',
            '&:hover': {
              borderColor: '#0a76ec',
            },
          }}
          href={resource.attributes.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexGrow={1}
          >
            <SoftTypography variant="button">
              {t('RESOURCES.openPDFButton')}
            </SoftTypography>
            <ArrowForwardIosIcon />
          </Stack>
        </SoftButton>
      </Stack>
    </Card>
  );
};

ResourceCard.propTypes = {
  resource: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      descriptionRichText: PropTypes.array,
      url: PropTypes.string.isRequired,
      image: PropTypes.shape({
        data: PropTypes.shape({
          attributes: PropTypes.shape({
            formats: PropTypes.shape({
              thumbnail: PropTypes.shape({
                url: PropTypes.string.isRequired,
              }).isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  openPDFButtonLabel: PropTypes.string.isRequired,
};

export default ResourceCard;
