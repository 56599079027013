import { useContext, useState } from 'react';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import { usePortalApi } from '../../../shared/const/api-backend';
import { useTranslation } from 'react-i18next';
import { UserDataContext } from 'shared/context/userDataContext';

const useFetchImportantUpdatesData = () => {
  const { t } = useTranslation();
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const { selectedBusinessUnit } = useContext(UserDataContext);
  const [data, setData] = useState(null);
  const API = usePortalApi();
  const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

  const fetchData = async (id = null) => {
    setLoading(true);
    try {
      let url;
      if (id) {
        // Fetch specific important update by ID
        url = `${apiStrapiUrl}updates/${id}?fields[0]=title&fields[1]=fullDescription&fields[2]=publishedAt&fields[3]=dateFormat`;
      } else {
        // Fetch list of important updates
        url = `${apiStrapiUrl}updates?populate[brands][fields]=brand&fields[0]=title&fields[1]=shortDescription&fields[2]=publishedAt&fields[3]=dateFormat&sort[0]=publishedAt:asc&filters[countries][country][$eqi]=${selectedBusinessUnit.country.country}&filters[$or][0][brands][brand][$eqi]=${selectedBusinessUnit.brand}&filters[$or][1][brands][brand][$null]=true`;
      }

      const response = await API.get(url);
      if (response.status !== 200) {
        throw new Error(t('COMMON.error.responseError'));
      }
      setData(response.data.data);
    } catch (error) {
      showNotification(t('COMMON.error.fetchingData'), 'error');
    } finally {
      setLoading(false);
    }
  };

  return { data, fetchData };
};

export default useFetchImportantUpdatesData;
