import React from 'react';
import PropTypes from 'prop-types';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import SoftTypography from '../SoftTypography';
import colors from '../../assets/theme/base/colors';

const BlockRenderer = ({ content }) => {
  if (!content || !Array.isArray(content) || content.length === 0) {
    return 'No content available';
  }

  return (
    <BlocksRenderer
      content={content}
      blocks={{
        list: ({ children, format }) => {
          if (format === 'unordered') {
            return <ul style={{ marginLeft: '2rem' }}>{children}</ul>;
          } else {
            return <ol style={{ marginLeft: '2rem' }}>{children}</ol>;
          }
        },
        paragraph: ({ children }) => (
          <SoftTypography variant="body2" sx={{ color: colors.black.main }}>
            {children}
          </SoftTypography>
        ),
      }}
    />
  );
};

BlockRenderer.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BlockRenderer;
