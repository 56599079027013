/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';

// react-router components
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Soft UI Dashboard PRO React components
// Soft UI Dashboard PRO React example components
import Sidenav from 'examples/Sidenav';

// Soft UI Dashboard PRO React themes
import theme from 'assets/theme';

// RTL plugins
// Soft UI Dashboard PRO React routes
import { routes, routesAvailableForAll } from 'routes';

// Soft UI Dashboard PRO React contexts
import { setOpenConfigurator, useSoftUIController } from 'context';

// Images
import brand from 'assets/images/logo-mini.png';
import { LoaderProvider } from './shared/context/loaderContext';
import Loader from './shared/components/Loader/Loader';
import ProtectedRoute from './shared/utils/_protectedRoute';
import SkeletonPage from './shared/components/SkeletonPage/SkeletonPage';
import Redirect from 'layouts/authentication/redirect/redirect';
import FctgLogin from './layouts/authentication/login-fctg';
import { AppConfigurationContext } from 'shared/context/configurationContext';
import { AuthContext } from 'AuthContext';
import Notification from './shared/components/Notification/Notification';
import { UserDataContext } from './shared/context/userDataContext';
import { getToken } from './shared/const/token.helper';

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { direction, openConfigurator, sidenavColor } = controller;
  const { pathname } = useLocation();
  const { isAuthenticated } = useContext(AuthContext);
  const {
    userData,
    selectedBrand,
    fetchUserDataFromApi,
    prevSelectedBusinessUnitSnapshot,
    applyPrevBusinessUnitState,
  } = useContext(UserDataContext);
  const {
    portalConfiguration,
    isRouteAvailableForRole,
    isRouteAvailableWhenQuotesLocked,
  } = useContext(AppConfigurationContext);
  const [loading, setLoading] = useState(true);
  const [filteredRoutes, setFilteredRoutes] = useState(routesAvailableForAll);
  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const location = useLocation();
  const prevLocationUrl = useRef(null);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const viewAllRoutesContext = ['policy-details', 'quote'];

    const checkRouteHasPolicyDetails = (route) => {
      const routes = route
        .split('/')
        .some(
          (currentSegment) =>
            !!viewAllRoutesContext.find(
              (searchedSegment) => currentSegment === searchedSegment,
            ),
        );
      return routes;
    };

    if (
      typeof prevLocationUrl.current === 'string' &&
      checkRouteHasPolicyDetails(prevLocationUrl.current) &&
      !checkRouteHasPolicyDetails(location.pathname) &&
      prevSelectedBusinessUnitSnapshot
    ) {
      applyPrevBusinessUnitState();
    }

    prevLocationUrl.current = location.pathname;
  }, [location, prevLocationUrl, prevSelectedBusinessUnitSnapshot]);

  useEffect(() => {
    const handleMessage = (event) => {
      // @TODO Ensure the message is from the correct origin
      if (event.data.type === 'REDIRECT_TO') {
        window.location.href = event.data.redirectLink;
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (getToken() && !userData) {
      setLoading(true);
      fetchUserDataFromApi()
        .then((data) => {
          if (data) {
            setFilteredRoutes(
              routes.filter(
                (route) =>
                  isRouteAvailableWhenQuotesLocked(route, data) &&
                  isRouteAvailableForRole(route, data),
              ),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [fetchUserDataFromApi]);

  useEffect(() => {
    if (userData) {
      setFilteredRoutes(
        routes.filter(
          (route) =>
            isRouteAvailableWhenQuotesLocked(route, userData) &&
            isRouteAvailableForRole(route, userData),
        ),
      );
      setLoading(false);
    }
  }, [userData, selectedBrand]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, index) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<ProtectedRoute>{route.component}</ProtectedRoute>}
            key={route.key || index}
          />
        );
      }

      return null;
    });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoaderProvider>
        <Loader />
        {loading ? (
          <SkeletonPage />
        ) : (
          <>
            {isAuthenticated && !pathname.includes('simplified-booking') && (
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="eShop Portal 2"
                routes={filteredRoutes}
                isLoading={portalConfiguration === null}
              />
            )}

            {!isAuthenticated ? (
              <Routes>
                <Route path="/login" element={<FctgLogin />} />
                <Route path="/fctg-sign-in" element={<FctgLogin />} />
                <Route
                  path="/api/connect/microsoft/redirect"
                  element={<Redirect provider="microsoft" />}
                />
                <Route
                  path="/connect/okta"
                  element={<Redirect provider="okta" />}
                />
                <Route
                  path="/connect/okta_external"
                  element={<Redirect provider="okta_external" />}
                />
                <Route
                  path="*"
                  element={
                    <ProtectedRoute>
                      <SkeletonPage />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            ) : (
              <Routes>
                {getRoutes(filteredRoutes)}
                <Route path="/" element={<Navigate to="/welcome-page" />} />
                <Route path="*" element={<Navigate to="/welcome-page" />} />
              </Routes>
            )}
          </>
        )}
        <Notification />
      </LoaderProvider>
    </ThemeProvider>
  );
}
